<template>
  <v-container>
    <v-data-table
      class="elevation-1 mt-5"
      :loading="$store.state.objects.isLoading"
      :headers="headers"
      :items="filteredObjects"
      :search="search"
      :header-props="headerProps"
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <div v-if="!$store.state.objects.isLoading">
            <CreateObjectDialog :active="dialogs.createObject" />
            <UpdateObjectDialog
              :active="dialogs.updateObject"
              :object="selectedObject"
            />
            <DeleteObjectDialog
              :active="dialogs.deleteObject"
              :object="selectedObject"
            />
            <v-btn
              color="primary"
              dark
              fab
              @click="createNewObject"
              v-if="$vuetify.breakpoint.mobile"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="createNewObject"
              v-else
            >
              Neues Objekt Hinzufügen
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="backAction">
            Zurück
          </v-btn>
        </v-toolbar>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          class="pa-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.objectType`]="{ item }">
        {{ getObjectType(item) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="!item.readOnly"
          small
          class="mr-2"
          @click="updateObject(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="!item.readOnly" small @click="deleteObject(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import CreateObjectDialog from "@/components/app/dialog/createObject.vue";
import UpdateObjectDialog from "@/components/app/dialog/updateObject.vue";
import DeleteObjectDialog from "@/components/app/dialog/deleteObject.vue";

export default {
  components: {
    CreateObjectDialog,
    UpdateObjectDialog,
    DeleteObjectDialog,
  },

  data() {
    return {
      dialogs: {
        createObject: 0,
        updateObject: 0,
        deleteObject: 0,
      },
      selectedObject: null,
      headers: [
        {
          text: "Bezeichnung",
          align: "start",
          value: "label",
        },
        { text: "Typ", value: "objectType" },
        { text: "Latitude", value: "geometry.coordinates[1]" },
        { text: "Longitude", value: "geometry.coordinates[0]" },
        { text: "Radius", value: "properties.radius" },
        { text: "Aktionen", value: "actions" },
      ],
      search: "",
      headerProps: {
        sortByText: "Sortierung",
      },
      footerProps: {
        itemsPerPageText: "Objekte pro Seite",
      },
    };
  },

  computed: {
    filteredObjects() {
      if (this.$route.query?.filter) {
        return this.$store.state.objects.objects
          .filter((object) => {
            return this.$store.getters.getUserWriteRights.includes(
              object.mainCategory
            );
          })
          .filter((obj) => obj.mainCategory == this.$route.query.filter);
      }
      return this.$store.state.objects.objects.filter((object) => {
        return this.$store.getters.getUserWriteRights.includes(
          object.mainCategory
        );
      });
    },
  },

  methods: {
    getObjectType(item) {
      if (item.objectType.indexOf("-") > 0) {
        return item.objectType.split(" - ")[1];
      }
      return item.objectType;
    },

    createNewObject() {
      this.dialogs.createObject++;
    },

    updateObject(object) {
      this.selectedObject = object;
      this.dialogs.updateObject++;
    },

    deleteObject(object) {
      this.selectedObject = object;
      this.dialogs.deleteObject++;
    },

    backAction() {
      this.$router.back();
    },
  },

  mounted() {
    this.$store.dispatch("attachObjectsOnSnapshotOld");
  },
};
</script>
