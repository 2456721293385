<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card>
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-title class="text-h6 pl-5">
          Neues Objekt erstellen
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-btn color="primary" dark @click="getCurrentCoordinate">
                  Standort abfragen
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.label"
                  label="Bezeichnung"
                  :rules="[rules.required]"
                  :loading="isLoadingMapGeocode"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :items="$store.getters.getSubCategories"
                  :loading="$store.state.rights.isLoading"
                  v-model="editedItem.objectType"
                  label="Objekt Typ"
                  :rules="[rules.required]"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="
                $store.getters.getMainCategory(editedItem.objectType) ==
                'Stempelgeraete'
              "
            >
              <v-col>
                <v-text-field
                  v-model.number="editedItem.properties.radius"
                  label="Radius (in meter)"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-if="
                $store.getters.getMainCategory(editedItem.objectType) ==
                'NEW Gebäude'
              "
            >
              <v-col>
                <v-textarea
                  v-model="editedItem.properties.description"
                  label="Beschreibung"
                  counter
                  maxlength="120"
                  full-width
                  single-line
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row
              v-if="
                $store.getters.getMainCategory(editedItem.objectType) ==
                  'In Planung' ||
                $store.getters.getMainCategory(editedItem.objectType) ==
                  'In Umsetzung'
              "
            >
              <v-col cols="12" sm="4">
                <v-select
                  v-model="editedItem.status"
                  label="Status"
                  :items="status"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="editedItem.properties.totalAmount"
                  label="Anzahl der Ladepunkte"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Gepl. Umsetzungsdatum"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="formatDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.properties.date"
                    @input="menu = false"
                    locale="de-DE"
                    :min="new Date().toISOString()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <GmapMap
                  ref="googleMapRef"
                  style="width: 100%; height: 350px"
                  :map-type-id="$store.state.map.type"
                  :zoom="mapProperties.defaultZoom"
                  :center="mapProperties.defaultCenter"
                  :options="{
                    mapTypeControl: true,
                    streetViewControl: false,
                    styles: mapProperties.defaultStyle,
                  }"
                >
                  <GmapMarker
                    :position="markerPosition"
                    :clickable="true"
                    :draggable="true"
                    @dragend="changeCoordinateHandler"
                  ></GmapMarker>
                  <GmapCircle
                    v-if="editedItem.objectType == 'Stempelgerät'"
                    :center="markerPosition"
                    :radius="parseFloat(editedItem.properties.radius)"
                    :visible="true"
                    :options="{
                      fillColor: 'blue',
                      fillOpacity: 0.1,
                      strokeOpacity: 0,
                    }"
                  ></GmapCircle>
                  <template v-if="editedItem.objectType == 'Stempelgerät'">
                    <GmapCircle
                      v-for="object in stempelgeraete"
                      :key="'circle' + object.docid"
                      :center="{
                        lat: object.geometry.coordinates[1],
                        lng: object.geometry.coordinates[0],
                      }"
                      :radius="parseFloat(object.properties.radius)"
                      :visible="true"
                      :options="{
                        fillColor: 'blue',
                        fillOpacity: 0.1,
                        strokeOpacity: 0,
                      }"
                    ></GmapCircle>
                  </template>
                </GmapMap>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  label="Latitude"
                  :value="markerPosition.lat"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  label="Longitude"
                  :value="markerPosition.lng"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog"> Abbrechen </v-btn>
        <v-btn
          :disabled="isNotValidForm"
          color="primary"
          text
          @click="saveObject"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { debounce } from "lodash";

export default {
  props: {
    active: Number,
    object: Object,
  },

  data() {
    return {
      dialogVisible: false,
      editedItem: {
        geometry: {
          coordinates: [6.447762, 51.15777],
          type: "Point",
        },
        label: "",
        objectType: "",
        status: "",
        properties: {
          radius: 50,
          description: "",
          date: null,
          type: null,
          totalAmount: 0,
        },
      },
      status: ["In Planung", "In Umsetzung"],
      menu: false,
      mapProperties: {
        defaultZoom: 14,
        defaultCenter: { lat: 51.15777, lng: 6.447762 },
        defaultStyle: [
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
      marker: null,
      rules: {
        required: (value) => !!value || "Required",
      },
      valid: true,
      markerPostalCode: "",
      markerCity: "",
      isLoadingMapGeocode: false,
      isDraggingMarker: false,
      isAddressGeocode: false,
      addressGeocodeCoordinate: null,
      isCurrentCoordinate: false,
      currentCoordinate: null,
      isUsingCurrentLocation: false,
    };
  },

  watch: {
    active() {
      this.dialogVisible = true;
    },

    "editedItem.label": debounce(function (val) {
      if (val.length > 5 && !this.isUsingCurrentLocation && !this.isDraggingMarker) {
        this.geocodeAddress(val);
      }
    }, 1000),
  },

  computed: {
    markerPosition() {
      if (this.marker) {
        return {
          lat: parseFloat(this.marker.latLng.lat().toFixed(6)),
          lng: parseFloat(this.marker.latLng.lng().toFixed(6)),
        };
      }
      if (this.isAddressGeocode) {
        return this.addressGeocodeCoordinate;
      }
      if (this.isCurrentCoordinate) {
        return this.currentCoordinate;
      }
      return {
        lat: this.editedItem.geometry.coordinates[1],
        lng: this.editedItem.geometry.coordinates[0],
      };
    },

    stempelgeraete() {
      return this.$store.state.objects.objects.filter(
        (obj) => obj.objectType == "Stempelgerät"
      );
    },

    isNotValidForm() {
      if (!this.editedItem.objectType) return true;
      if (
        this.editedItem.objectType == "Ladesäule" ||
        this.editedItem.objectType == "Schnellladesäule"
      ) {
        if (
          !this.editedItem.status ||
          this.editedItem.properties.totalAmount <= 0 ||
          this.editedItem.label == ""
        ) {
          return true;
        }
      }
      return false;
    },

    formatDate() {
      return this.editedItem.properties.date
        ? moment(this.editedItem.properties.date).locale("de").format("L")
        : "";
    },
  },

  methods: {
    closeDialog() {
      this.dialogVisible = false;
    },

    changeCoordinateHandler(position) {
      this.isLoadingMapGeocode = true;
      this.isDraggingMarker = true;
      this.marker = position;
      const latLng = {
        lat: parseFloat(this.marker.latLng.lat().toFixed(6)),
        lng: parseFloat(this.marker.latLng.lng().toFixed(6)),
      };
      this.getGeocodedAddress(latLng);
    },

    saveObject() {
      if (this.editedItem.label == "" || this.editedItem.objectType == "") {
        this.$store.commit("showSnackbar", {
          message: "Label und Objekttyp müssen ausgefüllt werden",
          color: "error",
        });
        return;
      }

      const data = {
        geometry: {
          coordinates: [
            parseFloat(this.markerPosition.lng),
            parseFloat(this.markerPosition.lat),
          ],
          type: "Point",
        },
        label: this.editedItem.label,
        objectType: this.editedItem.objectType,
        properties: {},
        readOnly: false,
        source: "manuell",
        postal_code: this.markerPostalCode,
        city: this.markerCity,
      };

      switch (this.$store.getters.getMainCategory(this.editedItem.objectType)) {
        case "Stempelgeraete":
          data.hauptKategorie = this.$store.getters.getMainCategory(
            this.editedItem.objectType
          );
          data.mainCategory = this.$store.getters.getMainCategory(
            this.editedItem.objectType
          );
          data.properties.radius = parseInt(this.editedItem.properties.radius);
          data.objectType = this.editedItem.objectType;
          break;
        case "NEW Gebäude":
          data.hauptKategorie = this.$store.getters.getMainCategory(
            this.editedItem.objectType
          );
          data.mainCategory = this.$store.getters.getMainCategory(
            this.editedItem.objectType
          );
          data.properties.description = this.editedItem.properties.description;
          data.objectType = this.editedItem.objectType;
          break;
      }

      if (this.editedItem.status == "In Planung") {
        data.hauptKategorie = this.editedItem.status;
        data.mainCategory = this.editedItem.status;

        data.objectType = `In Planung - ${this.editedItem.objectType}`;
        data.properties.date = this.formatDate;
        data.properties.type =
          this.editedItem.objectType == "Ladesäule" ? "AC" : "DC";
        data.properties.totalAmount = parseInt(
          this.editedItem.properties.totalAmount
        );
        data.properties.totalAvailableAmount = 0;
        if (this.editedItem.objectType == "Ladesäule") {
          data.properties.totalACAmount = parseInt(
            this.editedItem.properties.totalAmount
          );
          data.icon =
            "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20AC.png?alt=media&token=1c8015a5-c27e-4317-bda5-fcaec382a519";
        } else {
          data.properties.totalDCAmount = parseInt(
            this.editedItem.properties.totalAmount
          );
          data.icon =
            "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20DC%20Planung.png?alt=media&token=1c03d061-c0b9-4f62-a3ee-3f3a21397e00";
        }
        data.properties.objectType = this.editedItem.objectType;
        data.properties.status = this.editedItem.status;
      }

      if (this.editedItem.status == "In Umsetzung") {
        data.hauptKategorie = this.editedItem.status;
        data.mainCategory = this.editedItem.status;

        data.objectType = `In Umsetzung - ${this.editedItem.objectType}`;
        data.properties.date = this.formatDate;
        data.properties.type =
          this.editedItem.objectType == "Ladesäule" ? "AC" : "DC";
        data.properties.totalAmount = parseInt(
          this.editedItem.properties.totalAmount
        );
        data.properties.totalAvailableAmount = 0;
        if (this.editedItem.objectType == "Ladesäule") {
          data.properties.totalACAmount = parseInt(
            this.editedItem.properties.totalAmount
          );
          data.icon =
            "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20AC.png?alt=media&token=1c8015a5-c27e-4317-bda5-fcaec382a519";
        } else {
          data.properties.totalDCAmount = parseInt(
            this.editedItem.properties.totalAmount
          );
          data.icon =
            "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20DC%20Umsetzung.png?alt=media&token=00ce4494-f68d-476a-acbf-a4f0bb64852d";
        }
        data.properties.objectType = this.editedItem.objectType;
        data.properties.status = this.editedItem.status;
      }

      //  console.log({ data });

      this.$store.dispatch("createObject", {
        data,
      });

      this.closeDialog();
    },

    geocodeAddress(val) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: val }, (results, status) => {
        if (status == "OK") {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          this.addressGeocodeCoordinate = { lat, lng };
          this.mapProperties.defaultCenter = { lat, lng };
          this.isAddressGeocode = true;
        } else {
          console.error(status);
        }
      });
    },

    getCurrentCoordinate() {
      this.isUsingCurrentLocation = true;
      if ("geolocation" in navigator) {
        /* geolocation is available */
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.currentCoordinate = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.isCurrentCoordinate = true;
            this.mapProperties.defaultCenter = this.currentCoordinate;
            this.getGeocodedAddress(this.currentCoordinate);
          },
          (error) => {
            console.error(error.message);
            this.$store.commit("showSnackbar", {
              message: "Unbekannte Fehler!",
              color: "red",
            });
          },
          { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
        );
      } else {
        this.$store.commit("showSnackbar", {
          message: "Geolocation nicht verfügbar!",
          color: "red",
        });
      }
    },

    getGeocodedAddress(latLng) {
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({ location: latLng })
        .then(({ results }) => {
          console.log(results[0]);
          const street = results[0].address_components.find((component) =>
            component.types.includes("route")
          )?.long_name;
          const number = results[0].address_components.find((component) =>
            component.types.includes("street_number")
          )?.long_name;
          const postalCode = results[0].address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name;
          const city = results[0].address_components.find((component) => {
            return ["locality", "political"].every((value) => {
              return component.types.includes(value);
            });
          })?.long_name;
          const address = [street, number, postalCode, city];
          this.markerPostalCode = postalCode;
          this.markerCity = city;
          if (address.every((value) => value)) {
            this.editedItem.label = `${street} ${number}`;
          } else {
            this.editedItem.label = results[0].formatted_address;
          }
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isLoadingMapGeocode = false;
        });
    },
  },
};
</script>
